<template>
  <div>
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex flex-col items-center justify-center space-y-4 z-20"
    >
      <Spinner :color="'text-black'" :size="10" />
      <div class="mb-5 w-full" v-if="isUploading">
        <ProgressBar :progress="uploadProgress" />
      </div>
    </div>
    <div v-if="showAddExisting === true && document.document_id === undefined">
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm">
          Select Existing Document Number
          <span class="text-red-500">*</span>
        </label>
        <AutoComplete
          :multiple="true"
          v-model="selectedDocuments"
          field="document_number"
          :suggestions="filteredDocuments"
          @complete="searchCountry($event)"
          placeholder="Type here to search the documents"
          class="w-full"
        >
          <template #item="{ item, index }">
            <div class="autocomplete-row">
              <div class="autocomplete-code" :title="item.document_number">
                {{ item.document_number }}
              </div>
              <div class="autocomplete-name" :title="item.name">
                {{ item.name }}
              </div>
              <div class="autocomplete-category" :title="item.category">
                {{ item.category }}
              </div>
            </div>
          </template>
        </AutoComplete>
      </div>
      <div class="relative flex justify-end mt-5">
        <div
          v-show="!canAddDocuments"
          class="opacity-50 bg-white absolute inset-0"
        ></div>
        <button
          class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
          :disabled="!canAddDocuments"
          @click="addexistingDocuments"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Add Documents</span>
        </button>
      </div>
      <div class="flex items-center mb-5 mt-12">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
          Upload New Document
        </h3>
        <hr class="flex-1 border-primary mx-4" />
      </div>
    </div>

    <form @submit.prevent="saveDocument" class="relative">
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm"
          >Document Number
          <span class="text-red-500">*</span>
        </label>
        <input
          v-model="editingDoc.document_number"
          required
          class="bge-input bge-input-spacing w-full rounded"
        />
      </div>
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm"
          >Name
          <span class="text-red-500">*</span>
        </label>
        <input
          v-model="editingDoc.name"
          required
          class="bge-input bge-input-spacing w-full rounded"
        />
      </div>
      <div v-if="formType == 'New'" class="w-full mb-2">
        <label class="uppercase font-semibold text-sm">
          Visibility
          <span class="text-red-500">*</span>
        </label>
        <div class="w-full relative">
          <select
            class="bge-input bge-select rounded"
            v-model="editingDoc.visibility"
            required
          >
            <option>Internal</option>
            <option>Agent</option>
            <option>External</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm"> Category </label>
        <div class="w-full relative">
          <select
            class="bge-input bge-select rounded"
            v-model="editingDoc.category"
          >
            <option>Brochures</option>
            <option>Certifications</option>
            <option>Help &amp; Training</option>
            <option>Miscellaneous</option>
            <option>Product Manual</option>
            <option>Sample Plans</option>
            <option>Schematics</option>
            <option>Software</option>
            <option>Specifications</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
      <FileUpload v-if="formType == 'New'" :file.sync="editingDoc.file" />
      <div class="relative flex justify-end mt-5">
        <div
          v-show="!canSubmitForm"
          class="opacity-50 bg-white absolute inset-0"
        ></div>
        <button
          :disabled="isBusy || !canSubmitForm"
          type="submit"
          class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const FileUpload = () => import("@/components/FileUpload.vue");
const ProgressBar = () => import("@/components/ProgressBar.vue");
const RelatedProductsSearchField = () =>
  import("@/components/Documents/RelatedProductsSearchField.vue");

export default {
  name: "UploadPublishedDoc",
  components: {
    Spinner,
    Toggle,
    FileUpload,
    ProgressBar,
    RelatedProductsSearchField,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    showAddExisting: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
    },
    response: {
      type: Object,
    },
  },
  data() {
    return {
      editingDoc: {},
      formType: "New",
      isBusy: false,
      isUploading: false,
      uploadProgress: 0,
      contactName: "",
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
      customersEquipment: [],
      relatedProducts: [],
      filteredDocuments: null,
      documents: null,
      selectedDocuments: [],
    };
  },
  async mounted() {
    console.log(this.document);
    if (this.document.document_id) {
      this.formType = "Edit";
      try {
        this.isBusy = true;
        this.editingDoc = await this.DocumentService.getDocumentById(
          this.document.document_id,
        );
        console.log(this.editingDoc);
        this.relatedProducts = this.editingDoc.resource_tags;
        this.isBusy = false;
      } catch (error) {
        console.log(error);
        this.$emit("complete");
      }
    } else {
      this.editingDoc = {
        visibility: "Internal",
      };
    }
  },
  computed: {
    canSubmitForm() {
      console.log("File", this.editingDoc.file);
      if (this.formType == "Edit") {
        return (
          this.editingDoc.document_number &&
          this.editingDoc.name &&
          this.editingDoc.visibility
        );
      } else {
        return (
          this.editingDoc.file &&
          this.editingDoc.document_number &&
          this.editingDoc.name &&
          this.editingDoc.visibility
        );
      }
    },
    canAddDocuments() {
      return this.selectedDocuments.length > 0;
    },
  },
  methods: {
    async searchCountry(event) {
      try {
        let result = await this.DocumentService.searchDocuments(
          event.query.toLowerCase(),
        );
        this.filteredDocuments = result.data;
      } catch (error) {
        console.error(error);
      }
    },
    async addexistingDocuments() {
      this.isBusy = true;
      try {
        const promises = this.selectedDocuments.map(async (document) => {
          await this.DocumentService.attachResource(
            document.document_id,
            this.resource,
          );
        });

        await Promise.all(promises);

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Document Added",
                  close: onClose,
                },
              },
              "Your document was added successfully",
            );
          },
          {
            position: "top-right",
          },
        );
        this.isBusy = false;
        this.response = { isExisting: true };
        // this.$emit("complete", this.response);
        this.$emit("complete");
      } catch (error) {
        this.isBusy = false;
        console.log(error);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Save Error",
                  close: onClose,
                },
              },
              "There was a problem saving your document, please try again later",
            );
          },
          {
            position: "top-right",
          },
        );
      }
    },
    saveDocument: async function () {
      if (this.editingDoc.document_id) {
        this.isBusy = true;
        try {
          await this.DocumentService.updateDocument(
            this.editingDoc.document_id,
            {
              ...this.editingDoc,
            },
          );

          this.relatedProducts.forEach(async (product) => {
            await this.DocumentService.attachResource(
              this.editingDoc.document_id,
              {
                resource_type: "product",
                resource_id: product.product_id,
              },
            );
          });

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Document Saved",
                    close: onClose,
                  },
                },
                "Your document was saved successfully",
              );
            },
            {
              position: "top-right",
            },
          );
          this.isBusy = false;
          this.$emit("complete");
        } catch (error) {
          this.isBusy = false;
          console.log(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Save Error",
                    close: onClose,
                  },
                },
                "There was a problem saving your document, please try again later",
              );
            },
            {
              position: "top-right",
            },
          );
        }
      } else {
        this.isBusy = true;
        try {
          console.log("Doc Before upload", this.editingDoc);
          let uploadResult = await this.DocumentService.uploadDocument({
            ...this.editingDoc,
            resource_tags: [this.resource],
          });
          console.log(uploadResult);

          let documentId = uploadResult.document_id;

          // this.relatedProducts.forEach(async (product) => {
          //   await this.DocumentService.attachResource(documentId, {
          //     resource_type: "product",
          //     resource_id: product.product_id,
          //   });
          // });

          let accessUrl = uploadResult.access_url;
          console.log(accessUrl);
          let url = new URL(accessUrl);
          let accountUrl = url.origin;
          let sasToken = url.search;

          // Parse container
          let pathParts = url.pathname.split("/").filter((p) => {
            return p && p.trim() !== "";
          });
          let container = pathParts.length > 0 ? pathParts[0] : "";

          // Create filename
          let nonContainerParts = this.$_.drop(pathParts, 1);
          let filename = nonContainerParts.join("/");

          let blobService = AzureStorage.Blob.createBlobServiceWithSas(
            accountUrl,
            sasToken,
          );
          let customBlockSize =
            this.editingDoc.file[0].size > 1024 * 1024 * 32
              ? 1024 * 1024 * 4
              : 1024 * 128;
          blobService.singleBlobPutThresholdInBytes = customBlockSize;

          this.isUploading = true;
          let uploadSummary = blobService.createBlockBlobFromBrowserFile(
            container,
            filename,
            this.editingDoc.file[0],
            { blockSize: customBlockSize },
            async (error, result, response) => {
              if (error) {
                this.isUploading = false;
                // Upload blob failed
                this.$breadstick.notify(
                  ({ h, onClose }) => {
                    return h(
                      Notification,
                      {
                        props: {
                          type: "danger",
                          title: "Upload Error",
                          close: onClose,
                        },
                      },
                      "There was a problem uploading your document, please try again later",
                    );
                  },
                  {
                    position: "top-right",
                  },
                );
              } else {
                await this.DocumentService.updateDocument(documentId, {
                  is_uploaded: true,
                });

                this.$breadstick.notify(
                  ({ h, onClose }) => {
                    return h(
                      Notification,
                      {
                        props: {
                          title: "Document Uploaded",
                          close: onClose,
                        },
                      },
                      "Your document was uploaded successfully",
                    );
                  },
                  {
                    position: "top-right",
                  },
                );
                this.isUploading = false;
                this.isBusy = false;
                this.$emit("complete");
              }
              this.uploadProgress = 0;
            },
          );

          uploadSummary.on("progress", () => {
            this.uploadProgress = parseFloat(
              uploadSummary.getCompletePercent(),
            );
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
.autocomplete-row {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Added for vertical alignment if needed */
}

.autocomplete-code,
.autocomplete-name,
.autocomplete-category {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-code {
  width: 20%;
}

.autocomplete-name {
  width: 50%; /* Adjusted width */
}

.autocomplete-category {
  width: 30%; /* Added for the category column */
}
.p-autocomplete-panel {
  max-width: 100%;
  width: auto;
}

.p-autocomplete-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
